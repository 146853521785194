import { TENANTS } from "@app/lib/tenants/constants"
import { getTenant } from "@app/lib/tenants/getTenant"
import { isCasavo } from "@app/lib/tenants/isCasavo"
import { hasGivenMeasurementTrackingConsent } from "@app/lib/tracking/consent"
import { getValuationSessionId } from "@app/lib/tracking/tracking"
import {
  addGlobalContexts,
  newTracker,
  removeGlobalContexts,
  trackSelfDescribingEvent,
} from "@snowplow/browser-tracker"

const valuationFlowEvents = {
  valuationFlowStart: "iglu:com.casavo/valuation_flow_start/jsonschema/4-0-0",
  surveyQuestionPageVisit: "iglu:com.casavo/survey_question_page_visit/jsonschema/1-0-0",
  flowStartRedirect: "iglu:com.casavo/flow_start_redirect/jsonschema/1-0-0",
  confirmAddressPageVisit: "iglu:com.casavo/confirm_address_page_visit/jsonschema/1-0-0",
  sellerPropertyValuationSuccess: "iglu:com.casavo/seller_property_valuation_success/jsonschema/1-0-0",
  sellerPropertyValuationFailure: "iglu:com.casavo/seller_property_valuation_failure/jsonschema/1-0-0",
  flowPotentialBuyersShown: "iglu:com.casavo/flow_potential_buyers_shown/jsonschema/1-0-0",
  valuationResultVisit: "iglu:com.casavo/valuation_result_page_visit/jsonschema/2-0-0",
  confirmPhoneNumber: "iglu:com.casavo/confirm_phone_number/jsonschema/1-0-0",
  editPhoneNumber: "iglu:com.casavo/edit_phone_number/jsonschema/1-0-0",
  saveNewPhoneNumber: "iglu:com.casavo/save_new_phone_number/jsonschema/1-0-0",
  valuationFlowReferral: "iglu:com.casavo/valuation_flow_referral/jsonschema/1-0-0",
  valuationReasonExpressed: "iglu:com.casavo/valuation_reason_expressed/jsonschema/1-0-0",
  listingPricesDetailsModalOpen: "iglu:com.casavo/listing_prices_details_modal_open/jsonschema/1-0-0",
  businessVolumeBannerShown: "iglu:com.casavo/business_volume_banner_shown/jsonschema/1-0-0",
  addressEdited: "iglu:com.casavo/address_edited/jsonschema/1-0-0",
  interestInVisitCtaClick: "iglu:com.casavo/interest_in_visit_cta_click/jsonschema/2-0-0",
  listingPricesDetailsModalDuration: "iglu:com.casavo/listing_prices_details_modal_duration/jsonschema/1-0-0",
  propertyLocationSet: "iglu:com.casavo/property_location_set/jsonschema/1-0-0",
  contactFormPageVisit: "iglu:com.casavo/contact_form_page_visit/jsonschema/1-0-0",
  openAppOrPlayStoreForDataGathering: "iglu:com.casavo/open_app_or_play_store_for_data_gathering/jsonschema/1-0-0",
  resetValuationOnBrowserNav: "iglu:com.casavo/reset_valuation_on_browser_nav/jsonschema/1-0-0",
  compareYourHouseModalOpen: "iglu:com.casavo/compare_your_house_modal_open/jsonschema/1-0-0",
  compareYourHouseModalDuration: "iglu:com.casavo/compare_your_house_modal_duration/jsonschema/1-0-0",
}

const dataGatheringEvents = {
  dataGatheringStarted: "iglu:com.casavo/data_gathering_started/jsonschema/2-0-0",
  dataGatheringSurveyClick: "iglu:com.casavo/data_gathering_survey_click/jsonschema/1-0-0",
  dataGatheringHowShareWithBuyersWork: "iglu:com.casavo/data_gathering_how_share_with_buyers_work/jsonschema/1-0-0",
  dataGatheringMediaClick: "iglu:com.casavo/data_gathering_media_click/jsonschema/1-0-0",
  dataGatheringFloorPlanClick: "iglu:com.casavo/data_gathering_floor_plan_click/jsonschema/1-0-0",
  dataGatheringInfoSubmitted: "iglu:com.casavo/data_gathering_info_submitted/jsonschema/4-0-0",
  dataGatheringSurveyCompleted: "iglu:com.casavo/data_gathering_survey_completed/jsonschema/1-0-0",
  dataGatheringContinueBrowserClick: "iglu:com.casavo/data_gathering_continue_browser_click/jsonschema/1-0-0",
  dataGatheringMediaAdded: "iglu:com.casavo/data_gathering_media_added/jsonschema/2-0-0",
  dataGatheringFloorPlanCompleted: "iglu:com.casavo/data_gathering_floor_plan_completed/jsonschema/1-0-0",
  shareHouseInfoReconverted: "iglu:com.casavo/share_house_info_reconverted/jsonschema/2-0-0",
  shareHouseInfoRefusedReason: "iglu:com.casavo/share_house_info_refused_reason/jsonschema/1-0-0",
  dataGatheringSurveyAdded: "iglu:com.casavo/data_gathering_survey_added/jsonschema/2-0-0",
  dataGatheringSurveyQuestionPageVisit: "iglu:com.casavo/data_gathering_survey_question_page_visit/jsonschema/1-0-0",
  dataGatheringOtpVerificationError: "iglu:com.casavo/data_gathering_otp_verification_error/jsonschema/1-0-0",
}

const mandateEvents = {
  mandateSalesProposalExclusivityExplanationClick:
    "iglu:com.casavo/mandate_sales_proposal_exclusivity_explanation_click/jsonschema/1-0-0",
  mandateSalesProposalFeesExplanationClick:
    "iglu:com.casavo/mandate_sales_proposal_fees_explanation_click/jsonschema/1-0-0",
  mandateSalesProposalPriceExplanationClick:
    "iglu:com.casavo/mandate_sales_proposal_price_explanation_click/jsonschema/1-0-0",
  mandateSalesProposalTimeframeExplanationClick:
    "iglu:com.casavo/mandate_sales_proposal_timeframe_explanation_click/jsonschema/1-0-0",
  mandateSalesProposalVisit: "iglu:com.casavo/mandate_sales_proposal_visit/jsonschema/1-0-0",
  mandatePublishedVisit: "iglu:com.casavo/mandate_published_visit/jsonschema/1-0-0",
  mandateAcceptedVisit: "iglu:com.casavo/mandate_accepted_visit/jsonschema/1-0-0",
  mandateAcceptClick: "iglu:com.casavo/mandate_accept_click/jsonschema/1-0-0",
  mandateAcceptSuccessVisit: "iglu:com.casavo/mandate_accept_success_visit/jsonschema/1-0-0",
  mandateAcceptRetryClick: "iglu:com.casavo/mandate_accept_retry_click/jsonschema/1-0-0",
  mandateAcceptFailVisit: "iglu:com.casavo/mandate_accept_fail_visit/jsonschema/1-0-0",
  mandatePublishedRefreshNeedShown: "iglu:com.casavo/mandate_published_refresh_need_shown/jsonschema/1-0-0",
  downloadMobileAppClick: "iglu:com.casavo/download_mobile_app_click/jsonschema/1-0-0",
}

export const eventSchema = {
  ...valuationFlowEvents,
  ...dataGatheringEvents,
  ...mandateEvents,
  valuationsAreaReportPageVisit: "iglu:com.casavo/valuations_area_report_page_visit/jsonschema/1-0-0",
  valuationsAreaInterestInVisitCtaClick: "iglu:com.casavo/valuations_area_interest_in_visit_cta_click/jsonschema/1-0-0",
  valuationsAreaSellingGuideCategoryClick:
    "iglu:com.casavo/valuations_area_selling_guide_category_click/jsonschema/1-0-0",
  valuationsAreaWidgetClick: "iglu:com.casavo/valuations_area_widget_click/jsonschema/1-0-0",
  valuationsAreaSellingGuideArticleClick:
    "iglu:com.casavo/valuations_area_selling_guide_article_click/jsonschema/1-0-0",
  valuationsAreaHomePageVisitClient: "iglu:com.casavo/valuations_area_home_page_visit_client/jsonschema/1-0-0",
  valuationsAreaMarketingConsentClick: "iglu:com.casavo/valuations_area_marketing_consent_click/jsonschema/1-0-0",
  sellerProcessLoginResendOtpClick: "iglu:com.casavo/seller_process_login_resend_otp_click/jsonschema/2-0-0",
  sellerProcessLoginSendOtpClick: "iglu:com.casavo/seller_process_login_send_otp_click/jsonschema/2-0-0",
  sellerProcessLoginSubmitOtpClick: "iglu:com.casavo/seller_process_login_submit_otp_click/jsonschema/2-0-0",
} as const
export type EventSchema = typeof eventSchema

interface SnowplowBaseEvent<T extends object, K extends object = {}> {
  data: T
  sensitiveData?: K
}

interface SimpleEvent extends SnowplowBaseEvent<{}> {
  schema: EventSchema[
    | "confirmAddressPageVisit"
    | "confirmPhoneNumber"
    | "contactFormPageVisit"
    | "editPhoneNumber"
    | "saveNewPhoneNumber"
    | "listingPricesDetailsModalOpen"
    | "compareYourHouseModalOpen"]
}

interface ValuationFlowStartPayload {
  trafficSource?: string | null
  language: string | null
}

interface ValuationFlowStart extends SnowplowBaseEvent<ValuationFlowStartPayload> {
  schema: EventSchema["valuationFlowStart"]
  data: {
    valuationSessionId?: string | null
    trafficSource?: string | null
    language: string | null
  }
}

interface FlowStartRedirectPayload {
  addressComplete?: boolean
  source?: string | null
}

interface FlowStartRedirectEvent extends SnowplowBaseEvent<FlowStartRedirectPayload> {
  schema: EventSchema["flowStartRedirect"]
}

interface FlowPotentialBuyerShownPayload {
  potentialBuyersCount?: number
  inAreaPotentialBuyersCount?: number | null
}

interface FlowPotentialBuyerShown extends SnowplowBaseEvent<FlowPotentialBuyerShownPayload> {
  schema: EventSchema["flowPotentialBuyersShown"]
}

interface ValuationFlowReferralPayload {
  referralId?: string
  referralEmail?: string
  sellerPropertyValuationId?: string
  valuationSessionId?: string | null
}

interface ValuationFlowReferral extends SnowplowBaseEvent<ValuationFlowReferralPayload> {
  schema: EventSchema["valuationFlowReferral"]
}

interface ModalDurationEventPayload {
  durationInMs: number
}

interface ModalDurationEvent extends SnowplowBaseEvent<ModalDurationEventPayload> {
  schema: EventSchema["listingPricesDetailsModalDuration" | "compareYourHouseModalDuration"]
}

interface BusinessVolumeBannerShownPayload {
  type: "acquisitions" | "valuations"
  volume: number
}

interface BusinessVolumeBannerShown extends SnowplowBaseEvent<BusinessVolumeBannerShownPayload> {
  schema: EventSchema["businessVolumeBannerShown"]
}

interface AddressEditedPayload {
  editedFields: {
    city: boolean
    number: boolean
    street: boolean
    country: boolean
    zipCode: boolean
  }
}

interface AddressEdited extends SnowplowBaseEvent<AddressEditedPayload> {
  schema: EventSchema["addressEdited"]
}

export type InterestInVisitClickLocation =
  | "main"
  | "sticky"
  | "buyers_modal"
  | "compare_your_house_modal"
  | "listing_prices_suggestions_modal"

interface InterestInVisitCtaClickPayload {
  location?: InterestInVisitClickLocation
}

interface InterestInVisitCtaClick extends SnowplowBaseEvent<InterestInVisitCtaClickPayload> {
  schema: EventSchema["interestInVisitCtaClick"]
}

interface PropertyLocationSetPayload {
  country?: string | null
  city?: string | null
  zipCode?: string | null
  outOfArea?: boolean | null
}

interface PropertyLocationSet extends SnowplowBaseEvent<PropertyLocationSetPayload> {
  schema: EventSchema["propertyLocationSet"]
}

interface OpenAppOrPlayStoreForDataGatheringPayload {
  store?: "AppStore" | "PlayStore"
}

interface OpenAppOrPlayStoreForDataGathering extends SnowplowBaseEvent<OpenAppOrPlayStoreForDataGatheringPayload> {
  schema: EventSchema["openAppOrPlayStoreForDataGathering"]
}

interface ResetValuationOnBrowserNavPayload {
  destination?: string | null
  source?: string | null
}

interface ResetValuationOnBrowserNav extends SnowplowBaseEvent<ResetValuationOnBrowserNavPayload> {
  schema: EventSchema["resetValuationOnBrowserNav"]
}

interface ValuationResultPageVisitPayload {
  variant: string
  city?: string | null
}

interface ValuationResultPageVisit extends SnowplowBaseEvent<ValuationResultPageVisitPayload> {
  schema: EventSchema["valuationResultVisit"]
}

interface SurveyQuestionPageVisitPayload {
  questionIndex: number
  questionName: string | null
}

interface SurveyQuestionPageVisit extends SnowplowBaseEvent<SurveyQuestionPageVisitPayload> {
  schema: EventSchema["surveyQuestionPageVisit"]
}

interface SellerPropertyValuationSuccessSensitiveData {
  sellerPropertyValuationId?: string | null
}

interface SellerPropertyValuationSuccess extends SnowplowBaseEvent<{}, SellerPropertyValuationSuccessSensitiveData> {
  schema: EventSchema["sellerPropertyValuationSuccess"]
}

interface SellerPropertyValuationFailure
  extends SnowplowBaseEvent<
    { reason: "no_price_error" | "mail_or_number_already_present" | "unknown" },
    { valuationSessionId?: string | null }
  > {
  schema: EventSchema["sellerPropertyValuationFailure"]
}

interface ValuationReasonExpressedPayload {
  value?: string
}

interface ValuationReasonExpressed extends SnowplowBaseEvent<ValuationReasonExpressedPayload> {
  schema: EventSchema["valuationReasonExpressed"]
}

interface ValuationsAreaReportPageVisitPayload {
  potentialBuyersCount?: number
  inAreaPotentialBuyersCount?: number
  reconversionStrategy?: "with_visit" | "without_visit" | null
  dataGatheringStrategy?: "data_gathering" | "soft_data_gathering" | "not_applicable_lead_routing" | null
}

interface ValuationsAreaReportPageVisitSensitiveData {
  sellerPropertyValuationId?: string | null
}

interface ValuationsAreaReportPageVisit
  extends SnowplowBaseEvent<ValuationsAreaReportPageVisitPayload, ValuationsAreaReportPageVisitSensitiveData> {
  schema: EventSchema["valuationsAreaReportPageVisit"]
}

export type ValuationsAreaInterestInVisitCTALocation =
  | "main"
  | "sticky"
  | "buyers_modal"
  | "selling_time_modal"
  | "discount_modal"
  | "market_value_modal"
  | "selling_guide"

interface ValuationsAreaInterestInVisitCtaClickPayload {
  location?: ValuationsAreaInterestInVisitCTALocation
  reconversionStrategy?: "with_visit" | "without_visit" | null
  dataGatheringStrategy?: "data_gathering" | "soft_data_gathering" | "not_applicable_lead_routing" | null
}

interface ValuationsAreaInterestInVisitCtaClickSensitiveData {
  valuationSessionId?: string | null
  sellerPropertyValuationId?: string | null
}

interface ValuationsAreaInterestInVisitCtaClick
  extends SnowplowBaseEvent<
    ValuationsAreaInterestInVisitCtaClickPayload,
    ValuationsAreaInterestInVisitCtaClickSensitiveData
  > {
  schema: EventSchema["valuationsAreaInterestInVisitCtaClick"]
}

interface ValuationsAreaSellingGuideCategoryClickPayload {
  category?: "plan_the_selling" | "close_the_deal" | "prepare_the_house"
}

interface ValuationsAreaSellingGuideCategoryClickSensitiveData {
  sellerPropertyValuationId?: string | null
}

interface ValuationsAreaSellingGuideCategoryClick
  extends SnowplowBaseEvent<
    ValuationsAreaSellingGuideCategoryClickPayload,
    ValuationsAreaSellingGuideCategoryClickSensitiveData
  > {
  schema: EventSchema["valuationsAreaSellingGuideCategoryClick"]
}

export type ValuationsAreaWidgetClickType = "buyers" | "selling_time" | "discount" | "market_value"

interface ValuationsAreaWidgetClickPayload {
  type: ValuationsAreaWidgetClickType
}

interface ValuationsAreaWidgetClickSensitiveData {
  sellerPropertyValuationId?: string | null
}

interface ValuationsAreaWidgetClick
  extends SnowplowBaseEvent<ValuationsAreaWidgetClickPayload, ValuationsAreaWidgetClickSensitiveData> {
  schema: EventSchema["valuationsAreaWidgetClick"]
}

interface ValuationsAreaSellingGuideArticleClickPayload {
  articleUrl?: string
}
interface ValuationsAreaSellingGuideArticleClickSensitiveData {
  sellerPropertyValuationId?: string | null
}
interface ValuationsAreaSellingGuideArticleClick
  extends SnowplowBaseEvent<
    ValuationsAreaSellingGuideArticleClickPayload,
    ValuationsAreaSellingGuideArticleClickSensitiveData
  > {
  schema: EventSchema["valuationsAreaSellingGuideArticleClick"]
}

interface ValuationsAreaHomePageVisitClientPayload {
  availableValuationsCount?: number
  marketingConsentCardShown?: boolean
}
interface ValuationsAreaHomePageVisitClientSensitiveData {
  userId?: string | null
  availableValuations?: string[] | null
}
interface ValuationsAreaHomePageVisitClient
  extends SnowplowBaseEvent<ValuationsAreaHomePageVisitClientPayload, ValuationsAreaHomePageVisitClientSensitiveData> {
  schema: EventSchema["valuationsAreaHomePageVisitClient"]
}

interface ValuationsAreaMarketingConsentClickSensitiveData {
  userId?: string | null
}
interface ValuationsAreaMarketingConsentClick
  extends SnowplowBaseEvent<{}, ValuationsAreaMarketingConsentClickSensitiveData> {
  schema: EventSchema["valuationsAreaMarketingConsentClick"]
}

interface DataGatheringBaseEventPayload {
  dataGatheringId?: string
}

interface DataGatheringEventWithSource extends SnowplowBaseEvent<DataGatheringBaseEventPayload> {
  schema: EventSchema["dataGatheringStarted" | "dataGatheringMediaAdded"]
}

interface DataGatheringBaseEvent extends SnowplowBaseEvent<{}, { dataGatheringId: string }> {}

interface DataGatheringShareWithBuyers extends DataGatheringBaseEvent {
  schema: EventSchema["shareHouseInfoReconverted"]
  eventSource: "modal:howItWorks" | "webDataGatheringHome" | "mail" | "completedDataGathering"
  source: "website" | "app"
}

type DataGatheringInfoSubmitted = {
  schema: EventSchema["dataGatheringInfoSubmitted"]
} & SnowplowBaseEvent<
  {
    eventSource: "modal:howItWorks" | "webDataGatheringHome"
  },
  { dataGatheringId: string }
>

interface DataGatheringSimpleEvent extends DataGatheringBaseEvent {
  schema: EventSchema[
    | "dataGatheringSurveyClick"
    | "dataGatheringSurveyAdded"
    | "dataGatheringHowShareWithBuyersWork"
    | "dataGatheringMediaClick"
    | "dataGatheringFloorPlanClick"]
}

interface DataGatheringQuestionPageVisit
  extends SnowplowBaseEvent<
    {
      questionIndex: number
      questionName:
        | "sellingReason"
        | "condominiumDetails"
        | "propertyPosition"
        | "propertySpaces"
        | "parkingSpace"
        | "freeFrom"
        | "askingPrice"
    },
    { dataGatheringId: string }
  > {
  schema: EventSchema["dataGatheringSurveyQuestionPageVisit"]
}

interface DataGatheringContinueBrowserClickPayload {
  source: "website"
}
interface DataGatheringContinueBrowserClickSensitiveData {
  sellerPropertyValuationId?: string
}
interface DataGatheringContinueBrowserClick
  extends SnowplowBaseEvent<DataGatheringContinueBrowserClickPayload, DataGatheringContinueBrowserClickSensitiveData> {
  schema: EventSchema["dataGatheringContinueBrowserClick"]
}

interface ShareHouseInfoRefusedReasonPayload {
  data: {
    dataGatheringId?: string
    reason: "shareTooMuchInfos" | "notUnderstand"
  }
}
interface ShareHouseInfoRefusedReason extends SnowplowBaseEvent<ShareHouseInfoRefusedReasonPayload> {
  schema: EventSchema["shareHouseInfoRefusedReason"]
}

interface DataGatheringOtpVerificationErrorPayload {
  source?: "website"
  errorType?: "genericError" | "wrongCode"
}
interface DataGatheringOtpVerificationError extends SnowplowBaseEvent<DataGatheringOtpVerificationErrorPayload> {
  schema: EventSchema["dataGatheringOtpVerificationError"]
}

interface MandateSalesProposalExclusivityExplanationClick
  extends SnowplowBaseEvent<
    {},
    {
      transactionId?: string
    }
  > {
  schema: EventSchema["mandateSalesProposalExclusivityExplanationClick"]
}

interface MandateSalesProposalFeesExplanationClick
  extends SnowplowBaseEvent<
    {},
    {
      transactionId?: string
    }
  > {
  schema: EventSchema["mandateSalesProposalFeesExplanationClick"]
}

interface MandateSalesProposalPriceExplanationClick
  extends SnowplowBaseEvent<
    {},
    {
      transactionId?: string
    }
  > {
  schema: EventSchema["mandateSalesProposalPriceExplanationClick"]
}

interface MandateSalesProposalTimeframeExplanationClick
  extends SnowplowBaseEvent<
    {},
    {
      transactionId?: string
    }
  > {
  schema: EventSchema["mandateSalesProposalTimeframeExplanationClick"]
}
interface MandateSalesProposalVisit
  extends SnowplowBaseEvent<
    {},
    {
      transactionId?: string
    }
  > {
  schema: EventSchema["mandateSalesProposalVisit"]
}

interface MandatePublishedVisit
  extends SnowplowBaseEvent<
    {},
    {
      transactionId?: string
    }
  > {
  schema: EventSchema["mandatePublishedVisit"]
}
interface MandateApprovedVisit
  extends SnowplowBaseEvent<
    {
      pageMandateStatus: "won" | "approved"
    },
    {
      transactionId?: string
    }
  > {
  schema: EventSchema["mandateAcceptedVisit"]
}

interface MandateAcceptSuccessVisit
  extends SnowplowBaseEvent<
    {},
    {
      transactionId?: string
    }
  > {
  schema: EventSchema["mandateAcceptSuccessVisit"]
}

interface DownloadMobileAppClick
  extends SnowplowBaseEvent<
    {
      pageSource?: "mandate" | "shareWithBuyers" | "valuation"
      store: "PlayStore" | "AppStore"
    },
    {
      transactionId?: string
    }
  > {
  schema: EventSchema["downloadMobileAppClick"]
}

interface MandateApproveClick
  extends SnowplowBaseEvent<
    {},
    {
      transactionId?: string
    }
  > {
  schema: EventSchema["mandateAcceptClick"]
}

interface MandateAcceptRetryClick
  extends SnowplowBaseEvent<
    {},
    {
      transactionId?: string
    }
  > {
  schema: EventSchema["mandateAcceptRetryClick"]
}

interface DataGatheringFloorPlanCompleted
  extends SnowplowBaseEvent<{ uploadSkipped: boolean }, { dataGatheringId: string }> {
  schema: EventSchema["dataGatheringFloorPlanCompleted"]
}

interface MandatePublishedRefreshNeedShown
  extends SnowplowBaseEvent<
    {},
    {
      transactionId?: string
    }
  > {
  schema: EventSchema["mandatePublishedRefreshNeedShown"]
}

interface MandateAcceptFailVisit
  extends SnowplowBaseEvent<
    {},
    {
      transactionId?: string
    }
  > {
  schema: EventSchema["mandateAcceptFailVisit"]
}

interface SellerProcessLoginResendOtpClick extends SnowplowBaseEvent<{}, { transactionId: string }> {
  schema: EventSchema["sellerProcessLoginResendOtpClick"]
}

interface SellerProcessLoginSendOtpClick extends SnowplowBaseEvent<{}, { transactionId: string }> {
  schema: EventSchema["sellerProcessLoginSendOtpClick"]
}

interface SellerProcessLoginSubmitOtpClick extends SnowplowBaseEvent<{}, { transactionId: string }> {
  schema: EventSchema["sellerProcessLoginSubmitOtpClick"]
}

export type SnowplowEvent =
  | SimpleEvent
  | ValuationFlowStart
  | FlowStartRedirectEvent
  | FlowPotentialBuyerShown
  | ValuationFlowReferral
  | ModalDurationEvent
  | BusinessVolumeBannerShown
  | AddressEdited
  | InterestInVisitCtaClick
  | PropertyLocationSet
  | OpenAppOrPlayStoreForDataGathering
  | ResetValuationOnBrowserNav
  | ValuationResultPageVisit
  | SurveyQuestionPageVisit
  | SellerPropertyValuationSuccess
  | SellerPropertyValuationFailure
  | ValuationReasonExpressed
  | ValuationsAreaReportPageVisit
  | ValuationsAreaInterestInVisitCtaClick
  | ValuationsAreaSellingGuideCategoryClick
  | ValuationsAreaWidgetClick
  | ValuationsAreaSellingGuideArticleClick
  | ValuationsAreaHomePageVisitClient
  | ValuationsAreaMarketingConsentClick
  | DataGatheringSimpleEvent
  | DataGatheringShareWithBuyers
  | DataGatheringEventWithSource
  | DataGatheringQuestionPageVisit
  | DataGatheringContinueBrowserClick
  | DataGatheringFloorPlanCompleted
  | DataGatheringInfoSubmitted
  | ShareHouseInfoRefusedReason
  | DataGatheringOtpVerificationError
  | MandateSalesProposalExclusivityExplanationClick
  | MandateSalesProposalFeesExplanationClick
  | MandateSalesProposalPriceExplanationClick
  | MandateSalesProposalTimeframeExplanationClick
  | MandateSalesProposalVisit
  | MandatePublishedVisit
  | MandateAcceptFailVisit
  | MandateAcceptRetryClick
  | MandatePublishedRefreshNeedShown
  | MandateApproveClick
  | MandateApprovedVisit
  | DownloadMobileAppClick
  | SellerProcessLoginSendOtpClick
  | SellerProcessLoginResendOtpClick
  | SellerProcessLoginSubmitOtpClick

export const emitSnowplowEvent = ({ schema, data, sensitiveData }: SnowplowEvent) => {
  if (!isCasavo()) return
  const hasConsent = hasGivenMeasurementTrackingConsent()
  trackSelfDescribingEvent({
    event: {
      schema,
      data: {
        valuationSessionId: hasConsent ? getValuationSessionId() : null,
        ...(hasConsent && sensitiveData ? sensitiveData : {}),
        ...data,
      },
    },
  })
}

const snowplowEndpoint = process.env.NEXT_PUBLIC_SNOWPLOW_URL ?? "mini.analytics.data.int.casavo.tech"

export function initSnowplowTracker() {
  if (!!snowplowEndpoint && getTenant() === TENANTS.casavo) {
    newTracker("valuation-flow", snowplowEndpoint, {
      appId: "valuation-flow",
      plugins: [],
      contexts: {
        session: true,
      },
      cookieSameSite: "Lax",
      discoverRootDomain: true,
    })
  }
}

export function snowplowSetEventsTransactionId(transactionId: string | undefined) {
  const hasConsent = hasGivenMeasurementTrackingConsent()
  if (!hasConsent) return
  if (transactionId === undefined || transactionId.trim() !== "") return
  const contextData = {
    schema: "iglu:com.casavo/transactionId/jsonschema/1-0-0",
    data: {
      transactionId,
    },
  }
  removeGlobalContexts([contextData])
  addGlobalContexts([contextData])
}
